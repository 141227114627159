<template>
	<div id="app" class="h-100">
		<router-view/>
	</div>
</template>

<script>

export default {
	name: 'app',
	data: () => ({
		is_subscribed:''
	}),
	components: {

	},
	methods:{
		async isSubscribed() {
			if (localStorage.getItem('token') && localStorage.getItem('token') != '') {
				let responce = await this.$store.dispatch("isSubscribed");
				if (responce.status) {
					this.is_subscribed = responce.data.status;
					localStorage.setItem('is_subscribed', responce.data.status);
				}
			}
		},
		checkSubcription: function() {
			if (localStorage.getItem('token') && localStorage.getItem('token') != '') {
				return true;
				// if (!this.is_subscribed) {
				// 	window.notify(false, 'Please buy Subscription to use this feature.');
				// 	window.$('#subscription_modal').modal('show');
				// }
				// return this.is_subscribed;
			}
		},
	},
	created() {
		this.isSubscribed()
	}
}
</script>

<style>

</style>
