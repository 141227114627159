import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

Vue.use(Router,store);


// create object router with the valid initialization
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/',
      name: 'home',
      component: () => import('./views/home/landing.vue'),
      beforeEnter: guardRouteLogin,
      meta: {
        layout: 'full'
      }
    },{
      path: '/mobile/:subscription',
      name: 'mobile-subscription',
      component: () => import('./views/home/landing.vue'),
      beforeEnter: guardRouteLoginSubscription,
      meta: {
        layout: 'full'
      }
    },{
      path: '/dashboard',
      name: 'posts',
      component: () => import('./views/posts/posts.vue')
      // beforeEnter: guardRoute
    }, {
      path: '/chat',
      name: 'chats',
      component: () => import('./views/chat/chat.vue')
      // beforeEnter: guardRoute
    },{
      path: '/profile',
      name: 'profile',
      component: () => import('./views/profile/profile.vue')
      // beforeEnter: guardRoute
    },{
      path: '/my-profile',
      name: 'profile',
      component: () => import('./views/profile/profile.vue')
      // beforeEnter: guardRoute
    },{
      path: '/settings',
      name: 'settings',
      component: () => import('./views/settings/settings.vue')
      // beforeEnter: guardRoute
    },{
      path: '/view-details',
      name: 'details',
      component: () => import('./views/settings/details.vue')
      // beforeEnter: guardRoute
    },{
      path: '/subscription',
      name: 'subscription',
      component: () => import('./views/subscription/subscription.vue'),
      meta: {
        layout: 'full'
      }
      // beforeEnter: guardRoute
    },{
      path: '/payment',
      name: 'payment',
      component: () => import('./views/subscription/payment.vue')
      // beforeEnter: guardRoute
    },{
      path: '/feed/:id',
      name: 'profile',
      component: () => import('./views/profile/profile.vue')
      // beforeEnter: guardRoute
    },{
      path: '/chat/:id',
      name: 'chats',
      component: () => import('./views/chat/chat.vue')
      // beforeEnter: guardRoute
    },{
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('./views/home/privacy-policy.vue')
    },{
      path: '/terms-of-use',
      name: 'terms-of-use',
      component: () => import('./views/home/terms-of-use.vue')
    },{
      path: '/asd',
      name: 'asd',
      component: () => import('./views/home/landing.vue'),
      beforeEnter: guardRouteLogin,
      meta: {
        layout: 'full'
      }
    }

  ]
});

// function guardRoute (to, from, next) {
//   if (localStorage.getItem('token') && localStorage.getItem('token') != '') {
//     if(to.name == 'home'){
//       next('/dashboard')
//     }else{
//       next()
//     }
//   }
// }
function guardRouteLogin (to, from, next) {
  if (localStorage.getItem('token') && localStorage.getItem('token') != '') {
    if(to.name == 'home'){
      next('/dashboard');
    }
  }else{
    next();
  }
}

function guardRouteLoginSubscription (to, from, next) {
  if (localStorage.getItem('token') && localStorage.getItem('token') != '') {
      next('/subscription');
      window.setTimeout(function(){ location.reload(); }, 300);
  }else{
    if(to.path == '/mobile/subscription'){
      localStorage.setItem("is_mobile", true);
    }
    next('/');
    window.setTimeout(function(){ location.reload(); }, 300);
  }
}

// add the beforeEach hook
// router.beforeEach((to, from, next) => {
//   // redirect to login page if not logged in and trying to access a restricted page
//   const loggedIn = localStorage.getItem('user');
//   if (!loggedIn) {
//     return next('/');
//   }
//   next();
// });

export default router;