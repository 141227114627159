import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {API_URL,API_NODE_URL} from './constants'

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
    access_token: '',
  },

  mutations: {
    SET_TOKEN(state, data) {
      state.access_token = data;
    }
  },

  actions: {
    setEmail(context, payload) {
      context.commit(`SET_EMAIL`, payload.email);
    },

    varifyEmail(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_URL + 'verifyEmail', {
          email: payload.email,
          survey_id: payload.survey_id,
        }).then(response => {
          window.showLoader(false);
          if (response.data.status) {
            context.commit(`SET_TASKS`, response.data.data.task);
            context.commit(`SET_EMAIL`, payload.email);
            context.commit(`SET_COMPLETIONS`, response.data.data.task);
          }
          resolve(response);
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    submitRegistrationForm(context, payload) {
      return new Promise((resolve, reject) => {
        var bodyFormData = new FormData();
        // for( var i = 0; i < payload.profile_pic.length; i++ ){
        //   let file =  payload.profile_pic[i];
        //   bodyFormData.append('profile_pic', file);
        // }
    
        bodyFormData.set('username', payload.username);
        bodyFormData.set('email', payload.email);
        bodyFormData.set('password', payload.password);
        bodyFormData.set('first_name', payload.first_name);
        bodyFormData.set('last_name', payload.last_name);
        bodyFormData.set('dog_name', payload.dog_name);
        bodyFormData.set('breed', payload.breed);
        bodyFormData.set('profile_pic', payload.profile_pic);

        window.showLoader(true);
        axios.post(API_URL + 'register-user', bodyFormData,{
              headers: {'Content-Type': "application/x-www-form-urlencoded"}
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }else{
            window.notify(false, response.data.message);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    submitLoginForm(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_URL + 'login', {
          email: payload.email,
          password: payload.password,
          device_type: 'web',
          device_token: ''
        }).then(response => {
          window.showLoader(false);
          if (response.data.status) {
            localStorage.setItem('token', response.data.data.access_token)
            localStorage.setItem('user', response.data.id)
            context.commit(`SET_TOKEN`, response.data.data.access_token);
          }else{
            window.notify(false, response.data.message);
          }
          resolve(response);
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    getBreedList() {
      return new Promise((resolve, reject) => {
        axios.get(API_URL + 'get-breed', {}).then(response => {
          window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    getCountryList() {
      return new Promise((resolve, reject) => {
        axios.get(API_URL + 'get-country', {}).then(response => {
          window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    getAllStateList() {
      return new Promise((resolve, reject) => {
        axios.get(API_URL + 'get-state', {}).then(response => {
          window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    getStateList(context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(API_URL + 'get-state/'+ payload.country_id, {}).then(response => {
          window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    getSelectedUser(context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(API_URL + 'get-user/'+ payload.user_id, {}).then(response => {
          window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    getAuthUser() {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_URL + 'get-auth-user', {},{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    //-------------POSTS-----------

    getPostsList(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'posts', {
            page_type: 'before',
            post_id: payload.post_id,
            search_text: payload.search_text,
            breed: payload.breed,
            state: payload.state,
            gender: payload.gender,
            user_id: payload.user_id
          },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    getPostsByID(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'posts/get/'+payload.post_id, {},{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },


    createPost(context, payload) {
      return new Promise((resolve, reject) => {
        var bodyFormData = new FormData();
        for( var i = 0; i < payload.image.length; i++ ){
          let file =  payload.image[i];
          bodyFormData.append('images', file);
        }
    
        bodyFormData.set('title', payload.desc);
        bodyFormData.set('desc', payload.desc);

        window.showLoader(true);
        axios.post(API_NODE_URL + 'posts/create', bodyFormData,{
              headers: {Authorization: "Bearer " + localStorage.getItem('token'), 'Content-Type': "application/x-www-form-urlencoded"}
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    updatePost(context, payload) {
      return new Promise((resolve, reject) => {
        var bodyFormData = new FormData();
        for( var i = 0; i < payload.image.length; i++ ){
          let file =  payload.image[i];
          bodyFormData.append('images', file);
        }
    
        bodyFormData.set('title', payload.desc);
        bodyFormData.set('desc', payload.desc);
        bodyFormData.set('post_id', payload.post_id);

        window.showLoader(true);
        axios.post(API_NODE_URL + 'posts/update', bodyFormData,{
              headers: {Authorization: "Bearer " + localStorage.getItem('token'), 'Content-Type': "application/x-www-form-urlencoded"}
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    deletePost(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
          axios.post(API_NODE_URL + 'posts/delete', {
            post_id: payload.post_id,
          },{
              headers: {Authorization: "Bearer " + localStorage.getItem('token')}
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    deletePostImage(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
          axios.post(API_NODE_URL + 'posts/image/delete', {
            post_image_id: payload.post_image_id,
          },{
              headers: {Authorization: "Bearer " + localStorage.getItem('token')}
          }).then(response => {
            window.showLoader(false);
          if (response.status == 200) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },
    
    createComment(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'posts/comments/store', {
            post_id: payload.post_id,
            desc: payload.desc
          },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    getCommentList(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'posts/comments', {
            post_id: payload.post_id,
          },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    getLikeList(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'posts/likes', {
            post_id: payload.post_id,
          },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    // Friend Request Calls

    sendRequest(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'users/friend-requests/create', {
          user_id: payload.user_id,
        },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    updateRequest(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'users/friend-requests/update', {
          request_id: payload.from_id,
          status: payload.status,
        },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    pendingRequestList() {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'users/friend-requests', {},{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    firendsList() {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'users/friends', {},{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },


    // Chat Request Calls

    chatRecents() {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'chat/recent', {},{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    createNewChannel(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'chat/channel/find', {
          user_id: payload.user_id,
        },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    updateChannelStatus(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'chat/channel/update-status', {
          chat_channel_id: payload.chat_channel_id,
          status: payload.status,
        },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    blockChannel(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'chat/channel/block', {
          chat_channel_id: payload.chat_channel_id,
        },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    unblockChannel(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'chat/channel/unblock', {
          chat_channel_id: payload.chat_channel_id,
        },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    ChannelMessages(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'chat/channel/messages', {
          chat_channel_id:payload.chat_channel_id,
          timestamp:payload.timestamp
        },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    uploadChatFiles(context, payload) {
      return new Promise((resolve, reject) => {
        var bodyFormData = new FormData();
        for( var i = 0; i < payload.files.length; i++ ){
          let file =  payload.files[i];
          bodyFormData.append('files', file);
        }
        window.showLoader(true);
        axios.post(API_URL + 'upload-chat-files', bodyFormData,{
              headers: {Authorization: "Bearer " + localStorage.getItem('token'), 'Content-Type': "application/x-www-form-urlencoded"}
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    getStaticPage(context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(API_URL + 'get-static-page/'+ payload.slug, {}).then(response => {
          window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },
    
    //profile update form ...

    submitDogDetailsUpdateForm(context, payload) {
      return new Promise((resolve, reject) => {
        var bodyFormData = new FormData();
        for( var i = 0; i < payload.certificate.length; i++ ){
          let file =  payload.certificate[i];
          bodyFormData.append('certificate', file);
        }
    
        bodyFormData.set('dog_name', payload.dog_name);
        bodyFormData.set('breed', payload.breed);
        bodyFormData.set('gender', payload.gender);
        if (payload.dob != '' && payload.dob != null) {
          bodyFormData.set('dob', payload.dob);
        }
        bodyFormData.set('country_id', payload.country_id);
        bodyFormData.set('state_id', payload.state_id);
        bodyFormData.set('height', payload.height);
        bodyFormData.set('weight', payload.weight);
        bodyFormData.set('color', payload.color);
        bodyFormData.set('profile_pic', payload.profile_pic);

        window.showLoader(true);
        axios.post(API_URL + 'update-user', bodyFormData,{
              headers: {'Content-Type': "application/x-www-form-urlencoded",Authorization: "Bearer " + localStorage.getItem('token')}
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },
    
    submitOwnerDetailsUpdateForm(context, payload) {
      return new Promise((resolve, reject) => {
        var bodyFormData = new FormData();
    
        bodyFormData.set('first_name', payload.first_name);
        bodyFormData.set('last_name', payload.last_name);
        bodyFormData.set('username', payload.username);
        bodyFormData.set('email', payload.email);
        bodyFormData.set('phone', payload.phone);

        window.showLoader(true);
        axios.post(API_URL + 'update-user', bodyFormData,{
              headers: {'Content-Type': "application/x-www-form-urlencoded",Authorization: "Bearer " + localStorage.getItem('token')}
          }).then(response => {
            window.showLoader(false);
          if (response.data.status) {
            resolve(response);
          }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    //contact us
    submitContactUsForm(context, payload) {
      return new Promise((resolve, reject) => {
        var bodyFormData = new FormData();
    
        bodyFormData.set('first_name', payload.first_name);
        bodyFormData.set('last_name', payload.last_name);
        bodyFormData.set('email', payload.email);
        bodyFormData.set('message', payload.message);

        window.showLoader(true);
        axios.post(API_URL + 'contact-us', bodyFormData,{
              headers: {'Content-Type': "application/x-www-form-urlencoded",Authorization: "Bearer " + localStorage.getItem('token')}
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.message);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    // stripe
    startSubscription(context, payload) {
      return new Promise((resolve, reject) => {
        var bodyFormData = new FormData();
    
        bodyFormData.set('token_id', payload.token_id);

        window.showLoader(true);
        axios.post(API_URL + 'start-subscription', bodyFormData,{
              headers: {'Content-Type': "application/x-www-form-urlencoded",Authorization: "Bearer " + localStorage.getItem('token')}
          }).then(response => {
            window.showLoader(false);
            resolve(response);
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },
    isSubscribed() {
      return new Promise((resolve, reject) => {

        axios.post(API_URL + 'is-subscribed', {},{
              headers: {'Content-Type': "application/x-www-form-urlencoded",Authorization: "Bearer " + localStorage.getItem('token')}
          }).then(response => {
            window.showLoader(false);
            resolve(response);
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },
    retrieveSubscription() {
      return new Promise((resolve, reject) => {

        window.showLoader(true);
        axios.post(API_URL + 'retrieve-subscription', {},{
              headers: {'Content-Type': "application/x-www-form-urlencoded",Authorization: "Bearer " + localStorage.getItem('token')}
          }).then(response => {
            window.showLoader(false);
            resolve(response);
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },
    retrieveSubscriptionHistory() {
      return new Promise((resolve, reject) => {

        window.showLoader(true);
        axios.post(API_URL + 'retrieve-subscription-history', {},{
              headers: {'Content-Type': "application/x-www-form-urlencoded",Authorization: "Bearer " + localStorage.getItem('token')}
          }).then(response => {
            window.showLoader(false);
            resolve(response);
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },
    cancelSubscription() {
      return new Promise((resolve, reject) => {

        window.showLoader(true);
        axios.post(API_URL + 'cancel-subscription', {},{
              headers: {'Content-Type': "application/x-www-form-urlencoded",Authorization: "Bearer " + localStorage.getItem('token')}
          }).then(response => {
            window.showLoader(false);
            resolve(response);
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },
    resumeSubscription() {
      return new Promise((resolve, reject) => {

        window.showLoader(true);
        axios.post(API_URL + 'resume-subscription', {},{
              headers: {'Content-Type': "application/x-www-form-urlencoded",Authorization: "Bearer " + localStorage.getItem('token')}
          }).then(response => {
            window.showLoader(false);
            resolve(response);
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },
    
    //----------Notifications--------------

    userNotifications() {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'users/notifications', {
          list_type:'home'
        },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    updateToSeen(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'users/notifications/mark-seen', {
          notification_id:payload.notification_id,
        },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

    deleteNotification(context, payload) {
      return new Promise((resolve, reject) => {
        window.showLoader(true);
        axios.post(API_NODE_URL + 'users/notifications/delete', {
          notification_id:payload.notification_id,
        },{
              headers: { Authorization: "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            window.showLoader(false);
            if (response.data.status) {
              resolve(response);
            }else{
              window.notify(response.data.status, response.data.errors[0]);
            }
        }).catch(error => {
          window.showLoader(false);
          reject(error);
        });
      });
    },

  },
})