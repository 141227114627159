import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocketIO from 'vue-socket.io'
import {API_NODE_URL} from './constants'

// load css styles
import './assets/css/main.css';

Vue.config.productionTip = false

Vue.use(new VueSocketIO({
    debug: true,
    connection: API_NODE_URL,
    vuex: {
        store
    }//Optional options
}))

new Vue({
	router,
	store,
	render: h => h(App),
	sockets: {
        connect: function () {
            if (localStorage.getItem('token') && localStorage.getItem('token') != '') {
                var _this = this;
                console.log('socket connected');
                this.$socket.emit('user:connect', {token:localStorage.getItem('token')},function(data){
                    if(!data){
                        localStorage.setItem('token', '');
                        _this.$router.push("/");
                    }
                })
            }
        }
    },
}).$mount('#app')
